<template >
  <component
    :is="customComponent"
    :componentData="item"
    @saveComponent="saveItem"
  ></component>
</template>
<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    objectName: {
      type: [Array, Object, String],
    },
  },
  data() {
    return {};
  },
  computed: {
    customComponent() {
      return () => import("@/components/custom/" + this.objectName + ".vue");
    },
  },
  methods: {
    saveItem(event) {
      this.item.Value = event;
    },
  },
};
</script>
  
  <style>
</style>